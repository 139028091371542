@import "~antd/dist/antd.less";

@primary-color: #fccc11;
@menu-item-color: #f0f2f5;
@menu-item-active-border-width: 5px;
@layout-body-background: #f0f2f5;
@menu-highlight-color: #fccc11;
@btn-primary-color: black;
@btn-primary-bg: #fccc11;
@divider-color: rgba(0, 0, 0, 10%);

.prefixIcon {
  color: @primary-color;
  font-size: 15px;
  margin-right: 5px;
}

.ant-form-large .ant-form-item-label > label {
  min-height: 20px;
  height: inherit;
  font-weight: 500;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: black;
}

.txt-primary {
  color: @primary-color;
}

//Inputs
.ant-input-lg {
  // padding: 14px 16px;
  // font-size: 16px;
  border-radius: 3px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: inherit;
}

.ant-input-affix-wrapper {
  border-radius: 2px;
}

.ant-input-affix-wrapper-lg {
  border-radius: 5px;
  padding: 5.2px 11px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
}

#normal_login_country_code {
  padding-left: 5px;
}

#normal_login_phone {
  padding-left: 5px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #fccc11;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #fccc11;
}

//Buttons
.ant-btn-lg {
  height: 48px !important;
  padding: 0px 16px !important;
  border-radius: 5px !important;
  font-size: 17px !important;
}

.ant-btn {
  height: 38px;
  border-radius: 2px;
  font-size: 15px;
}

//Modal
.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-body {
  padding: 0px;
}

