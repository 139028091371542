.loginContainer {
  height: 100vh;
  background: #168781;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContainer:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  background-image: url("../../assets/img/skyline.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.loginForm {
  max-width: 328px;
  margin: auto;
  /* margin-top: -100px; */
}

.intro {
  font-family: cursive;
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.intro .title {
  font-size: 20px;
}

.quote {
  width: 100%;
  max-width: 450px;
  padding-bottom: 50px;
}

.quoteSm {
  max-width: 350px;
  padding-bottom: 30px;
}

.swirl {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: -90px;
  margin-left: -250px;
  z-index: 1;
}

.logo {
  position: fixed;
  height: 60px;
  top: 18px;
  left: 20px;
  z-index: 2;
  cursor: pointer;
}
