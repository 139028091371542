.layout {
  min-height: 100vh;
}

.mainContent {
  /* padding: 10px 0 0; */
  padding: 0 50px;
  margin-top: 64px;
}

.mainContentSm {
  padding: 0px;
  margin-top: 64px;
}

.header {
  height: 65px;
  position: fixed;
  z-index: 1001;
  width: 100%;
  background: #168781;
  box-shadow: 0 1px 2px rgb(3 49 86 / 20%);
}

.header .logo {
  padding: 0px 0px 0px 20px;
  width: 200px;
}

.header .nav {
  margin: auto;
  text-align: center;
  font-size: 18px;
  background: #168781;
}

.nav .icon {
  font-size: 18px;
}

.header .avatar {
  text-align: right;
}

.menu {
  background: transparent;
}

.menu .item {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.navItem {
  height: 50px;
  line-height: 40px;
  font-size: 16px;
}

.navItem .icon {
  font-size: 15px !important;
  margin-right: 15px !important;
}
