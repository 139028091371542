body {
  margin: 0;
  background: #f0f2f5 !important;
}

.container {
  padding: 24px;
  background: #fff;
  max-width: 1000px;
  margin: 0px auto 16px auto;
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.container-md {
  padding: 16px;
  background: #fff;
  max-width: 800px;
  margin: 0px auto 16px auto;
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.container-md-t {
  padding: 16px;
  background: transparent;
  max-width: 800px;
  margin: 0px auto 16px auto;
}

.container-sm {
  padding: 24px;
  background: #fff;
  max-width: 500px;
  margin: 0px auto 16px auto;
  box-shadow: 0 0.8rem 0.8rem 0 rgb(17 17 17 / 8%);
}

.containerMobile {
  padding: 0px;
  background: #fff;
}

.w-100 {
  width: 100%;
}

.h-100v {
  height: 100vh;
}

.pointer {
  cursor: pointer;
}

.bg-blue {
  background: #1c5777;
}

.divider-text {
  color: rgba(0, 0, 0, 20%);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-white {
  color: white;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.m-h-a {
  margin-left: 0px;
  margin-right: 0px;
}

.positive {
  color: #3f8600 !important;
}

.average {
  color: #faad14 !important;
}

.negative {
  color: #cf1322 !important;
}

.modal-body {
  padding: 24px;
  border-radius: 3px;
}
