.section {
  width: 90%;
  margin: auto;
  min-height: 200px;
}

.content {
  /* margin-top: 40px; */
  /* margin-bottom: 20px; */
  /* max-width: 400px; */
}

.buttons {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.buttons .left {
  float: left;
}

.buttons .right {
  float: right;
}

.pitch {
  height: 200px !important;
}

.ftBtn {
  /* margin-top: 23px; */
}

.reportSection {
  padding: 0px 20px 10px 20px;
}

.reportAlert {
  margin-top: 30px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
}

.reportAlertSm {
  font-size: 12px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
}

.perc {
  padding: 3px 10px 3px 10px;
  border-radius: 4px;
  display: inline-block;
}

.percSelected {
  background-color: #168781;
  color: white;
  padding: 3px 10px 3px 10px;
  border-radius: 4px;
  display: inline-block;
}
